<template>
	<a-config-provider
		style="min-height: 100vh"
		:theme="{
			token: {
				colorPrimary: '#44142a',
				colorPrimaryBg: '#fcdfec',
			},
		}"
	>
		<router-view />
	</a-config-provider>
</template>

<script lang="ts" setup></script>

<style scoped>
	#components-layout-demo-side .logo {
		height: 32px;
		margin: 16px;
		background: rgba(255, 255, 255, 0.3);
	}
	.imgLogo {
		width: 100%;
	}

	.site-layout .site-layout-background {
		background: #fff;
	}
</style>
