import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import loginView from "@/views/LoginView.vue";
import DeleteAccount from "@/views/deleteAccount.vue";


const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "home",
		component: loginView,
	},
	{
		path: "/login",
		name: "login",
		component: loginView,
	},
	{
		path: "/delete-account",
		name: "delete-account",
		component: DeleteAccount,
	},

];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach((to, from, next) => {
	const isAuthenticated = localStorage.getItem("user") !== null;

	// Check if the route requires authentication and the user is not authenticated
	if (to.meta.requiresAuth && !isAuthenticated) {
		// Redirect to the login page with the original requested route as a query parameter
		next({ name: "login", query: { redirect: to.fullPath } });
	} else {
		next(); // Proceed to the requested route
	}
});

export default router;
