<template>
	<div class="styleDiv">
		<a-form class="form">
			<div>
				<img src="../assets/logo.png" alt="Vue logo" class="image" />
				<div :style="{ display: 'flex', justifyContent: 'center ' }">
					<div class="deleteImage"><DeleteFilled style="font-size: 2.5em; color: #522537" /></div>
				</div>
				<div style="text-align: center; margin: 10px 0 5px 0; font-size: 20px; color: #522537; font-weight: 600; letter-spacing: 0.5px; font-family: sans-serif">Delete Account</div>
				<div style="text-align: center; margin: 10px 0 25px 0; font-size: 15px; color: gray">When you delete your account, your profile, photos, chats, likes and matches will be permanently removed. <br /><br /><b>You may instead choose to hide yourself from explore in the app settings.</b></div>
				<div style="text-align: center; margin: 10px 0 20px 0; font-size: 15px; color: gray">Please check and confirm your email address</div>
			</div>
			<!-- <div class="flexDisplay mrgb">
				<div style="display: flex; justify-content: end; width: 90px">
					<div>userId :</div>
				</div>
				<div class="ant-form-text" type="secondary">{{ userId }}</div>
			</div> -->
			<div class="flexDisplay mrgb">
				<!-- <div style="display: flex; justify-content: end; width: 90px">
					<div>Email :</div>
				</div> -->
				<div class="ant-form-text" type="secondary">{{ identifier }}</div>
			</div>
			<div class="center">
				<a-button type="primary" size="large" class="btn" @click="handleDelete">Delete Account</a-button>
			</div>
		</a-form>
	</div>
</template>

<script lang="ts" setup>
	import { DeleteFilled, ExclamationCircleOutlined } from "@ant-design/icons-vue";
	import { Modal } from "ant-design-vue";
	import { createVNode, ref } from "vue";
	import { deletedUserApi } from "@/services/api/auth";
	import { openNotificationWithIcon } from "@/utils";
	import router from "@/router";

	const identifier = ref("");
	const userId = ref("");

	const fetchData = () => {
		const userData = localStorage.getItem("user");
		if (userData) {
			if (userData && typeof userData === "string") {
				const user = JSON.parse(userData);
				if (user.identifier) {
					identifier.value = user.identifier;
				}
				if (user._id) {
					userId.value = user._id;
				}
			}
		}
	};
	fetchData();

	const handleDelete = () => {
		Modal.confirm({
			centered: true,
			width: 500,
			title: "Are you sure you want to delete this account ?",
			icon: createVNode(ExclamationCircleOutlined),
			okText: "Delete",
			okType: "danger",
			cancelText: "Cancel",
			async onOk() {
				try {
					const userData = localStorage.getItem("user");
					if (userData) {
						const user = JSON.parse(userData);
						const deleteUser = await deletedUserApi(user._id, "deleteUser");
						if (deleteUser) {
							localStorage.removeItem("user");
							openNotificationWithIcon("success", "Account deleted successfully");
						} else {
							openNotificationWithIcon("error", "Something went wrong, contact support team.");
						}
					}
					router.push("/login");
				} catch (error) {
					openNotificationWithIcon("error", "Something went wrong");
				}
			},
		});
	};
</script>
<style scoped>
	.styleDiv {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	.form {
		background-color: #fff;
		width: 400px;
		padding: 1rem 2rem;
		box-shadow: 0 10px 25px rgba(92, 99, 105, 0.2);
		.ant-form-item-label {
			position: relative;
			height: 48px;
		}
	}
	.image {
		width: 55%;
		height: auto;
		margin: auto;
		display: block;
	}
	.flexDisplay {
		display: flex;
		font-size: 16px;
		justify-content: center;
	}
	.mrgb {
		margin-bottom: 20px;
	}
	.ant-form-text {
		font-weight: 600;
		font-size: 18px;
		font-family: sans-serif;
		letter-spacing: 0.3px;
	}
	.center {
		display: flex;
		justify-content: center;
		gap: 10px;
	}
	.deleteImage {
		border: 2px solid #522537;
		border-radius: 60px;
		width: 90px;
		height: 90px;
		display: flex;
		justify-content: center;
		margin-top: 30px;
	}
	.btn {
		width: 80%;
		margin-top: 10px;
	}
</style>
